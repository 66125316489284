import React, { useState, useEffect, Fragment } from "react"
import { format } from "date-fns"
import {
  LegacyCard,
  Layout,
  LegacyStack,
  Spinner,
  DataTable,
  Link,
  Badge,
  Tooltip,
  Button,
  Text,
} from "@shopify/polaris";
import exportData from "../../../helpers/exportData"
import getOrdersWithUpsells from "../../../helpers/getOrdersWithUpsells"

const Insights = (props) => {
  const {
    TotalTitle,
    date,
    chartDateRange,
    rules = [],
    state,
    formatCurrency,
    locale,
    currency,
    token,
    shop,
    host,
    analytics,
    totalImpression
  } = props
  const [ordersWithUpsellsLoading, setOrdersWithUpsellsLoading] = useState(
    false
  )
  const [ordersWithUpsells, setOrdersWithUpsells] = useState([])
  const [exportLoading, setExportLoading] = useState(false)
  const [topSellingProducts, setTopSellingProducts] = useState([]);
  useEffect(() => {
    const run = async () => {
      setOrdersWithUpsellsLoading(true)
      try {
        const result = await getOrdersWithUpsells(
          {
            startDate: format(chartDateRange.startDate, "yyyy-MM-dd"),
            endDate: format(chartDateRange.endDate, "yyyy-MM-dd"),
          },
          token,
          shop,
          host
        )
        setOrdersWithUpsells(result.data)
      } catch (e) {
        console.log(e)
      }
      setOrdersWithUpsellsLoading(false)
    }
    run()
    getTopSellProducts()
  }, [])

  const getTopSellProducts = () => {
    let products = []
    analytics.map((anaytic) => {
      const orderCreateEntries = Object.entries(anaytic.orderCreate || {});
      let offerImpression = 0
      orderCreateEntries.map(([offerId, orderData]) => {
        const productEntries = Object.entries(orderData || {});
        if (typeof orderData == 'object') {
          analytics.map((analyItem) => {
            const impressionEntries = Object.entries(analyItem.impressions || {});
            if (analyItem?.impressions?.totalCount > 0) {
              impressionEntries.map(([impressionKey, impressionData]) => {
                if (typeof impressionData == 'object') {
                  if (impressionKey == offerId) {
                    offerImpression = offerImpression + parseInt(impressionData?.totalCount);
                  }
                }
              })
            }
          })
        }
        productEntries.map(([productKey, productData]) => {
          if (typeof productData == "object") {
            const alreadyData = products?.filter((item) => item.id == productKey);
            if (alreadyData.length > 0) {
              let updatedproducts = products?.map((item) => item.id == productKey ? {...item, totalCount: item.totalCount + productData.totalCount, totalUpsoldRevenue: item.totalUpsoldRevenue + productData.totalUpsoldRevenue, offerId: offerId, offerImpression: offerImpression} : {...item, offerId: offerId, offerImpression: offerImpression});
              products = [...updatedproducts];
            } else {
              products.push({...productData, id: productKey, offerId: offerId, offerImpression: offerImpression});
            }
          }
        })
      })
    })

    let topProducts = products.sort((a, b) => b.totalUpsoldRevenue - a.totalUpsoldRevenue).slice(0, 15);
    setTopSellingProducts(topProducts);
  }

  const getOfferType = (rule) => {
    if (rule.offerType) {
      let offerTypeTitle = ""
      switch (rule.offerType) {
        case "Checkout":
          return rule?.checkoutType && "extension" === rule?.checkoutType ? (
            <Badge tone="info">Checkout Extension</Badge>
          ) : (
            <Badge tone="info">Checkout.liquid</Badge>
          );
        case "Cart":
          offerTypeTitle =
            rule?.cartType && "page" === rule?.cartType
              ? "Cart Page"
              : rule?.cartType && "drawer" === rule?.cartType
              ? "Cart Drawer"
              : "Cart Popup"
          return <Badge tone="warning">{offerTypeTitle}</Badge>;
        default:
          return (
            <span
              className="Polaris-Badge Polaris-Badge--statusSuccess"
              style={{ background: "#A6A4F2" }}
            >
              {rule.offerType}
            </span>
          )
      }
    } else {
      return <Badge tone="info">Checkout</Badge>;
    }
  }
  const onClickOrderLink = (link) => {
    if (link) {
      window.open(link, "_blank")
    }
  }
  const exportOrdersData = async () => {
    setExportLoading(true)
    const result = await exportData(
      {
        startDate: format(date[0], "yyyy-MM-dd"),
        endDate: format(date[1], "yyyy-MM-dd"),
      },
      props.token,
      props.shop,
      host
    )
    var a = document.createElement("a")
    const blob = new Blob([result.data], { type: "octet/stream" }),
      url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = `upsellplusexportdata-${format(
      date[0],
      "yyyy-MM-dd"
    )}-${format(date[1], "yyyy-MM-dd")}.csv`
    document.body.append(a)
    a.click()
    a.remove()
    window.URL.revokeObjectURL(url)
    setExportLoading(false)
  }
  return (
    <Fragment>
      <Layout.Section>
        <LegacyStack alignment="center">
          <LegacyStack.Item fill>
            <Text variant="headingMd" as="h2">Insights</Text>
          </LegacyStack.Item>
          <LegacyStack.Item>
            <div style={{ float: "right" }}>
              <Button  onClick={() => exportOrdersData()} variant="primary">
                Export Data
                {exportLoading ? (
                  <Spinner
                    accessibilityLabel="Small spinner today"
                    size="small"
                  />
                ) : (
                  false
                )}
              </Button>
            </div>
          </LegacyStack.Item>
        </LegacyStack>
      </Layout.Section>
      {
      <Layout.Section>
        <LegacyCard
          title={
            <LegacyStack>
              <LegacyStack.Item fill>
                <Tooltip
                  content=""
                  dismissOnMouseOut
                >
                  <TotalTitle title="Top selling products" />
                </Tooltip>
              </LegacyStack.Item>
              <LegacyStack.Item>
                <span>{state.dateRangeLabel}</span>
              </LegacyStack.Item>
            </LegacyStack>
          }
          sectioned
        >
          {ordersWithUpsellsLoading ? (
            <Spinner accessibilityLabel="Spinner example" size="large" />
          ) : (
            <DataTable
              columnContentTypes={[
                "text",
                "numeric",
                "numeric"
              ]}
              headings={[
                "Product",
                "Revenue",
                "Conversion"
              ]}
              rows={topSellingProducts.map((product) => {
                return [
                  product.name,
                  formatCurrency(product.totalUpsoldRevenue, locale, currency),
                  totalImpression != 0 ? ((product.totalCount/product.offerImpression)*100).toFixed(2).toString()+"%" : "0.00%"
                ]
              })}
            />
          )}
        </LegacyCard>
      </Layout.Section>
      }
      <Layout.Section>
        <LegacyCard
          title={
            <LegacyStack>
              <LegacyStack.Item fill>
                <Tooltip
                  content="Shows last 15 orders with upsells. For all upsold orders in your selected date range, click 'Export data'"
                  dismissOnMouseOut
                >
                  <TotalTitle title="Orders with upsells" />
                </Tooltip>
              </LegacyStack.Item>
              <LegacyStack.Item>
                <span>{state.dateRangeLabel}</span>
              </LegacyStack.Item>
            </LegacyStack>
          }
          sectioned
        >
          {ordersWithUpsellsLoading ? (
            <Spinner accessibilityLabel="Spinner example" size="large" />
          ) : (
            <DataTable
              columnContentTypes={[
                "text",
                "text",
                "text",
                "text",
                "text",
                "numeric",
                "numeric",
                "numeric",
              ]}
              headings={[
                "Order",
                "Date",
                "Offer",
                "Type",
                "Upsell product",
                "Upsell revenue",
                "Cart Total",
                "AOV lift",
              ]}
              rows={ordersWithUpsells.map((order) => {
                const offers = rules.filter(
                  (rule) =>
                    Array.isArray(order["Offer IDs"]) &&
                    order["Offer IDs"].includes(rule.id)
                )
                const offerNames = offers.map((offer) => offer.name).join(", ")
                const orderId = (
                  <Link
                    removeUnderline
                    key={order["Order ID"]}
                    onClick={() => onClickOrderLink(order["Link"])}
                  >
                    {order["Order ID"]}
                  </Link>
                )
                const offerType =
                  offers.length > 0 ? getOfferType(offers[0]) : ""
                return [
                  orderId,
                  order["Date"],
                  offerNames,
                  offerType,
                  order["Upsell product"],
                  formatCurrency(order["Upsell revenue"], locale, currency),
                  formatCurrency(order["Cart total"], locale, currency),
                  order["AOV lift"],
                ]
              })}
            />
          )}
        </LegacyCard>
      </Layout.Section>
    </Fragment>
  );
}
export default Insights
